import axios from 'axios'

export const resize = function (val, width, height, quality, enlarge) {
  if (!val || !val.length) { return '' }
  const q = quality ? '&q=' + quality : ''
  enlarge = enlarge ? '&enlarge=true' : ''
  const src = Buffer.from(val).toString('base64')
  return `https://img.glyphs.co/img?src=${src}&w=${width}&h=${height}&fit=crop${enlarge}${q}`
}

export const validStatusList = ['valid', 'catch-all', 'unknown']

export const validateEmail = async function (email) {
  console.log('validating email: ', email)
  const k1 = '94d06b8bdd4f4e78'
  let url = 'https://api-us.zerobounce.net/v2/validate?api_key='
  const k2 = '96d4d28022e319e4'
  url += k1 + k2 + '&ip_address=&email=' + encodeURIComponent(email)

  // create new Axios instance and remove Auth headers to get CORS working with Filestack
  const instance = axios.create()
  delete instance.defaults.headers.common

  const { data } = await instance.get(url)

  const validation = {
    email: data.address,
    status: data.status,
    subStatus: data.sub_status,
    isValid: validStatusList.includes(data.status)
  }

  console.log('email validation response: ', validation)
  return validation
}
