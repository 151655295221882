const getNhostService = (service) => {
  if (process.env.NHOST_SUBDOMAIN && process.env.NHOST_REGION) {
    return `https://${process.env.NHOST_SUBDOMAIN}.${service}.${process.env.NHOST_REGION}.nhost.run/v1`
  }
  if (process.env.BACKEND_URL) {
    return `${process.env.BACKEND_URL}/v1/${service}`
  }
  throw new Error('set either BACKEND_URL or NHOST_SUBDOMAIN and NHOST_REGION')
}

// CommonJS syntax so it can be used in Netlify functions
module.exports = {
  getNhostStorageUrl: () => getNhostService('storage'),
  getNhostAuthUrl: () => `https://auth.slideswith.com/v1`,
  getNhostGraphqlUrl: () => `https://graphql.slideswith.com/v1/graphql`
}
