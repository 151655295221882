// plugins/apollo-error-handler.js
export default async (error, ctx) => {
  if (error.message.includes('Data frame received after close')) {
    window.location.reload()
  }
  if (error.message.includes('JWTExpired') && ctx.app.$auth.loggedIn) {
    await ctx.app.$auth.refreshToken()
  } else if (error.message.includes('field') && error.message.includes('not found in type:') && ctx.app.$auth.loggedIn) {
    console.log('apollo error: ', error)
    // await ctx.app.$auth.logout()
  } else {
    console.log('global apollo error: ', error)
  }
}
