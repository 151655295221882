import Vue from 'vue'
import { getQuery, withQuery, $URL } from 'ufo'

Vue.mixin({
  computed: {
    isDev () {
      return this.$store.state.isDev
    }
  },
  methods: {
    log (...args) {
      if (this.isDev) {
        args.forEach((arg) => {
          console.log(arg)
        })
      }
    },
    trackEvent (event) {
      if (!event.name) {
        if (typeof event === 'string' || event instanceof String) {
          event = { name: event }
        } else {
          console.log(`error tracking event: ${event} is not a valid event object or string`)
          return false
        }
      }
      if (this.isDev) {
        console.log('[DEV] Would have tracked event: ', event)
      } else {
        this.$gtag.event(event.action || 'click', {
          'event_category': event.category || 'Interactions',
          'event_label': event.name,
          'value': event.value,
          'currency': Number.isInteger(event.value) ? 'USD' : undefined
        })
      }
    },
    // lowercase posthog for consistency with plugin and docs
    posthogCapture (event, data) {
      if (!this.$posthog) {
        this.log('Posthog not initialized. Captured event would have been: ', event, data)
        return
      }

      this.log('[POSTHOG] ' + event, data || {})

      this.$posthog.capture(event, data)
    },
    throwError (error, type) {
      type = type || 'notification'
      const data = error.response && error.response.data ? error.response.data : {}
      console.error(error, data)
      let message = data.message || 'Uh oh: ' + JSON.stringify(error)

      if (message === 'Account does not exist.' || message === 'Password does not match.') {
        message = 'Incorrect password or that account does not exist'
      }

      if (type === 'notification') {
        this.$buefy.notification.open({
          message,
          duration: 30000,
          type: 'is-danger',
          position: 'is-bottom-left'
        })
      }

      if (type === 'toast') {
        this.$buefy.toast.open({
          message,
          type: 'is-danger'
        })
      }
      this.error = message
    },
    findParentByName (componentName) {
      let component = null
      let parent = this.$parent
      while (parent && !component) {
        if (parent.$options.name === componentName) {
          component = parent
        }
        parent = parent.$parent
      }
      return component
    },
    pluralize (number) {
      return Math.abs(number) === 1 ? '' : 's'
    },
    hasSeenHint (name) {
      return this.$store.state.userHints.includes(name)
    },
    saveUserHint (name) {
      this.$store.commit('saveUserHint', name)
    },
    hasOnboarded (name) {
      const user = this.$store.state.user
      if (!user.onboardCues) { return false }
      return user.onboardCues.includes(name)
    },
    saveOnboarding (name, remove) {
      if (this.hasOnboarded(name) && !remove) { return }
      const user = this.$store.state.user
      if (!user?.id || !user.onboardCues) { this.log('no logged in user'); return }
      const onboardCues = [ ...user.onboardCues ]

      if (remove) {
        const index = onboardCues.indexOf(name)
        if (index > -1) {
          onboardCues.splice(index, 1)
        }
      } else {
        onboardCues.push(name)
      }

      this.$store.dispatch('saveOnboardCues', onboardCues)

      if (window.Intercom) {
        window.Intercom('trackEvent', name)
      }
    },
    resize (src, opts) {
      const url = new $URL(src)

      const base = url.pathname
      // Use hasura-storage image transformation service
      // if (src.includes('back.slideswith.com')) {
      //   base = 'https://lcljzgg8.twic.pics/storage/'
      //   const path = url.pathname.replace('/storage/o', '')
      //   base = base + path
      // } else {
      //   base = 'https://lcljzgg8.twic.pics/static/'
      //   const path = url.pathname
      //   base = base + path
      // }

      const query = getQuery(src)
      query.h = opts.h
      query.w = opts.w
      // const twic = []

      // if (opts.w && !opts.h) {
      //   twic.push('resize=' + opts.w + 'x-')
      // }

      // if (!opts.w && opts.h) {
      //   twic.push('resize=-x' + opts.h)
      // }

      // if (opts.w && opts.h && opts.fit !== 'contain') {
      //   twic.push('cover=' + opts.w + 'x' + opts.h)
      // }

      // if (opts.w && opts.h && opts.fit === 'contain') {
      //   twic.push('contain-max=' + opts.w + 'x' + opts.h)
      // }

      // if (opts.format) {
      //   twic.push('output=' + opts.format)
      // }

      // query.twic = 'v1/quality=85/' + twic.join('/')

      return withQuery(base, query)
    },
    cfResize (src, opts = {}) {
      const relativeSrc = !(src.indexOf('http://') === 0 || src.indexOf('https://') === 0)
      if (relativeSrc) {
        src = 'https://slideswith.com/' + src
      }

      const pattern = /svg$/i
      if (pattern.test(src)) { return src } // return url directly if it's an SVG

      const base = 'https://slideswith.com/cdn-cgi/image/'

      opts.metadata = 'none'
      opts.onerror = false

      let str = ''
      for (const opt in opts) {
        str = str + opt + '=' + opts[opt] + ','
      }

      str = str.replace(/,$/, '')

      return base + str + '/' + src
    }
  }
})
