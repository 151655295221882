import jwtDecode from 'jwt-decode'
import { getNhostAuthUrl } from '../common/nhost-url'

export default async function ({ app, $auth }) {
  const AUTH_URL = getNhostAuthUrl()

  $auth.logout = async () => {
    try {
      const { status } = await app.$axios.post(AUTH_URL + '/signout', {
        refreshToken: $auth.strategy.refreshToken.get()
      })
      if (status === 200) {
        await $auth.reset()
      } else {
        console.log('unable to logout nhost, resetting locally')
        await $auth.reset()
      }
    } catch (e) {
      console.log('unable to logout nhost, resetting locally')
      await $auth.reset()
    }
  }

  $auth.getAccessToken = () => $auth.strategy.token.get()

  $auth.refreshToken = async function (refreshToken) {
    if (!refreshToken && !$auth.loggedIn) {
      // console.log('user logged out, not refreshing token')
      return
    }
    if (!refreshToken) {
      refreshToken = $auth.strategy.refreshToken.get()
    }
    // console.log('user logged in, trying manual token refresh')
    if (refreshToken) {
      // const jwt = $auth.strategy.token.get()
      // const status = $auth.strategy.token.status()
      if ($auth._refreshing) {
        return
      }
      $auth._refreshing = true
      try {
        const { data, status } = await app.$axios.post(AUTH_URL + '/token', {
          refreshToken
        })
        // console.log('token manually refreshed')
        if (status === 200) {
          await $auth.strategy.token.set(`Bearer ${data.accessToken}`)
          setUser($auth)
          app.apolloProvider.defaultClient.wsClient.close(false)
        }
      } catch (e) {
        // console.log('error manually refreshing token', e)
        $auth.logout()
      } finally {
        $auth._refreshing = false
      }
    }
  }

  $auth.changeEmail = async function (newEmail) {
    await app.$axios.post(
      AUTH_URL + '/user/email/change',
      {
        newEmail,
        options: {
          redirectTo: window.location.origin + '/confirm-email'
        }
      },
      {
        headers: {
          Authorization: $auth.getAccessToken()
        }
      }
    )
  }

  $auth.changePassword = async function (newPassword, ticket) {
    await app.$axios.post(
      AUTH_URL + '/user/password',
      {
        newPassword,
        ticket
      }
    )
  }

  $auth.resetPassword = async function (email) {
    await app.$axios.post(AUTH_URL + '/user/password/reset', {
      email,
      options: {
        redirectTo: window.location.origin + '/reset-password'
      }
    })
  }

  // try refreshing manually to be sure we have a valid access token
  await $auth.refreshToken()

  // console.log($auth)

  const token = $auth.getAccessToken()

  // set auth headers if logged in with valid token, skip if not
  app.apolloProvider.defaultClient.wsClient.connectionParams = () => {
    const jwt = $auth.getAccessToken()
    // console.log('setting connection params with token: ', jwt)
    if (jwt) {
      return {
        headers: { Authorization: jwt }
      }
    }
    return {}
  }

  // set user state if loggedIn and not already there
  if (token && (!$auth.$state.user || !$auth.$state.user.id)) {
    setUser($auth)
  }

  // start token refresher, 5 minutes interval for 15 min jwt
  setInterval(() => {
    $auth.refreshToken()
  }, 60000 * 5)
}

async function setUser ($auth) {
  // console.log('setting user from jwt claims')
  const token = await $auth.getAccessToken()

  let claims = jwtDecode(token)
  claims = claims['https://hasura.io/jwt/claims']

  // console.log('setting user from jwt claims: ', claims)

  const user = {
    id: claims['x-hasura-user-id'],
    default_role: claims['x-hasura-default-role'],
    teamIds: claims['x-hasura-Team-Ids']
  }

  $auth.setUser(user)
}
