import Vue from 'vue'

function longestWordLength (string) {
  return string.split(' ').sort((a, b) => b.length - a.length)[0].length
}

function fitText (el, binding, vnode) {
  const lineLength = binding.value.length
  const maxLines = binding.value.lines
  const baseSize = binding.value.base || 1
  const maxSize = binding.value.max || 1
  const minSize = binding.value.min || 0
  const text = el.textContent
  const strLength = text.length

  // intercept any bad data
  if (!text || !text.length || text.length === 0) { return }

  // scale by total chars
  const maxChars = lineLength * maxLines
  const charScale = strLength > 0 ? maxChars / strLength : 1

  // scale by max line length and longest word
  const longestWord = longestWordLength(text)
  const lineScale = longestWord > 0 ? lineLength / longestWord : 1

  // calculate modifier with the smallest scale so it's guaranteed to fit
  const modifier = Math.min(charScale, lineScale)

  // update el size
  el.style.lineHeight = '1.2em'
  el.style.fontSize = Math.max(Math.min(baseSize * modifier, maxSize), minSize) + 'em'
}

Vue.directive('fit-text', {
  inserted: fitText,
  bind: fitText,
  update: fitText,
  componentUpdated: fitText
})
