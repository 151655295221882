import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default ({ app }) => {
  Vue.use(
    VueGtag,
    {
      config: { id: 'G-HE4XL9DMNZ' },
      includes: [ { id: 'UA-174982451-1' } ],
      appName: 'Slides with Friends',
      pageTrackerExcludedRoutes: [
        '/embed/decks',
        '/embed/pricing'
      ]
    },
    app.router
  )
}
