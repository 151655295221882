import createPersistedState from 'vuex-persistedstate'

export default ({ store }) => {
  createPersistedState({
    key: 'swf',
    paths: [
      'participant',
      'userHints',
      'activeEvent',
      'pastEvents',
      'popularDecks',
      'emailSubscriber',
      'showTestControls',
      'eventPreviews',
      'lastClosedEvent',
      'onboardingClosed',
      'confirmEmailModalShown',
      'confirmationEmailSent'
    ],
    setState: (key, state, storage) => {
      // make sure that older or blank activeEvents are not saved over newer ones
      const stored = JSON.parse(storage.getItem('swf')) || {}
      const newActiveEvent = state.activeEvent || {}
      const storedActiveEvent = stored.activeEvent || {}

      if (storedActiveEvent.id) {
        if (!newActiveEvent.id || newActiveEvent.lastActive < storedActiveEvent.lastActive) {
          // current state ActiveEvent change it to the newest (stored) version
          state.activeEvent = storedActiveEvent
        }
      }

      // unrelated mutation, save it
      return storage.setItem(key, JSON.stringify(state))
    }
  })(store)
}
