<template>
  <div>
    <ClientOnly>
      <script id="profitwell-js" data-pw-auth="8daa3dd7ffb46b59a6820a6e6d011b26">
        (function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
        a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
        s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
        })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');
      </script>
    </ClientOnly>
    <nuxt />
  </div>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable quotes */

import _get from 'lodash.get'
import { parseISO, getUnixTime } from 'date-fns'
import { validStatusList } from '../common/functions'
import subscribeToUser from '~/queries/subscribeToUser.gql'
import { updateLastSeen } from '~/queries/updateUserProfile.gql'

let phUserProperties = {}

export default {
  data () {
    return {
      intercomMounted: false,
      intercomBooted: false,
      intercomUpdated: false,
      firstUpdatePending: false,
      profitwellBooted: false,
      gaStarted: false,
      triedUpdateIntercom: 0,
      appVersion: process.env.COMMIT_REF,
      snackbarOpen: 0
    }
  },

  computed: {
    intercomDisabled () {
      return process.env.DISABLE_INTERCOM === 'true' || window.Cypress || false
    },
    auth () {
      return this.$store.state.auth
    },
    user () {
      return this.$store.state.user
    },
    userAudience () {
      if (!this.user || !this.user.id || !this.user.feedback) { return null }
      return this.user.feedback.find(resp => resp.questionId === 1)
    },
    eventFrequency () {
      if (!this.user || !this.user.id || !this.user.feedback) { return null }
      return this.user.feedback.find(resp => resp.questionId === 2)
    },
    groupSize () {
      if (!this.user || !this.user.id || !this.user.feedback) { return null }
      return this.user.feedback.find(resp => resp.questionId === 3)
    }
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'auth.loggedIn': async function (newVal) {
      if (newVal === true) {
        await this.startProfileSubscription()
      } else {
        this.$apollo.subscriptions.userProfile.stop()
        this.$store.commit('setUserProfile', {})
      }
    }
  },
  mounted () {
    this.startProfileSubscription()

    // link stripe and other domains in GA4
    setTimeout(() => {
      this.$gtag.linker({
        'domains': ['checkout.stripe.com']
      })
    }, 3000)

    // check build version to see if there is an update
    setInterval(() => {
      this.checkForAppUpdate()
    }, 60000)
    this.checkForAppUpdate()
  },
  methods: {
    async checkForAppUpdate () {
      if (this.$route.name === 'event-id') { return }
      const { data } = await this.$axios.get(process.env.SITE_URL + '/_nuxt/info')
      if (data?.ver?.length < 10 || this.appVersion?.length < 10) { return }
      if (data.ver !== this.appVersion) {
        if (this.snackbarOpen < 5) {
          this.promptToRefresh()
        } else if (this.$route.hash !== '#refresh') {
          window.location.hash = '#refresh'
          window.location.reload()
        }
      }
    },
    promptToRefresh () {
      this.snackbarOpen++
      this.$buefy.snackbar.open({
        message: 'Slides With Friends has a new version!  Please refresh your page now to update (your data will be saved)',
        type: 'is-primary',
        position: 'is-top',
        actionText: 'Refresh',
        indefinite: true,
        onAction: () => {
          this.snackbarOpen = 0
          window.location.reload()
        }
      })
    },
    async startProfileSubscription () {
      if (!this.$auth.loggedIn) {
        this.updateIntercom() // start intercom for loggedOut visitors
        this.setServerTime()
        return
      }

      if (this.$apollo.subscriptions.userProfile) {
        await this.$apollo.subscriptions.userProfile.start()
        this.updateIntercom() // reboot on a new page after user subscription has restarted
        this.$nextTick(() => { this.updateLastSeen() })
      } else {
        // this.log('adding user profile subscription')
        await this.$apollo.addSmartSubscription('userProfile', {
          query: subscribeToUser,
          variables () {
            return {
              userId: this.$auth.user.id
            }
          },
          result ({ data }) {
            const user = data.user || {}
            // this.log('user profile update', user)
            this.$store.commit('setUserProfile', user)
            this.updateIntercom()
            this.setAnalyticsId()
            this.posthogIdentify()
          }
        })
        this.$nextTick(() => { this.updateLastSeen() })
      }
    },
    posthogIdentify () {
      if (this.$auth.loggedIn && this.$auth.user.id && this.$posthog) {
        const user = this.$store.state.user
        const userProperties = {
          email: user.email,
          user_email: user.email,
          user_name: user.displayName,
          user_creation_date: user.createdAt,
          user_stripe_id: user.subscription?.customer,
          user_subscription_status: user.subscription?.status,
          user_plan: this.$store.getters.userPlan,
          user_event_count: user.event_count,
          user_deck_count: _get(user, 'slideDecks_aggregate.aggregate.count')
        }

        if (JSON.stringify(phUserProperties) === JSON.stringify(userProperties)) {
          this.log('[POSTHOG SKIP] User properties are identical, not setting them again')
          return
        }

        this.log('[POSTHOG] Identifying user, setting properties: ', userProperties)
        phUserProperties = userProperties

        let firstPage
        if (localStorage && localStorage.getItem('firstLanding')) {
          firstPage = JSON.parse(localStorage.getItem('firstLanding'))
        }

        if (firstPage?.url?.length > 3) {
          userProperties.first_page = firstPage.url
        }

        this.$posthog.identify(
          user.id,
          userProperties
        )

        this.$posthog.startSessionRecording()
      }
    },
    setAnalyticsId () {
      if (this.$auth.loggedIn && this.$auth.user.id && !this.gaStarted) {
        this.log('setting GA client id')
        this.$gtag.set({ 'user_id': this.$auth.user.id })
        this.gaStarted = true
      }
    },
    setServerTime () {
      // set server time and client offset to sync timers as
      this.$store.dispatch('setServerTime')
    },
    async updateLastSeen () {
      if (!this.$auth.user.id || !this.$auth.loggedIn) { return }

      await this.$apollo.mutate({
        mutation: updateLastSeen,
        variables: {
          userId: this.$auth.user.id
        }
      })

      this.setServerTime()
    },
    mountIntercom () {
      // this.log('mounting intercom')
      const intercom = document.createElement('script')
      intercom.setAttribute('src', 'https://widget.intercom.io/widget/a772tk9n')
      intercom.setAttribute('defer', '')
      document.head.appendChild(intercom)
      this.intercomMounted = true
    },
    updateIntercom () {
      if (this.intercomDisabled) { return } // Cypress detected or Intercom disabled from ENV, aborting initialization

      if (!this.intercomMounted) { this.mountIntercom() }

      let userData = { app_id: 'a772tk9n' }

      const user = this.$store.state.user
      const passes = { ...this.$store.getters.eventPasses }
      const sub = user.subscription || {}

      if (user.id) {
        const userCreatedTime = getUnixTime(parseISO(user.createdAt))
        const justRegistered = getUnixTime(new Date()) - userCreatedTime < 10

        if (justRegistered) {
          if (this.firstUpdatePending) { return }
          this.log('just registered, waiting 10 seconds to update intercom')
          setTimeout(() => {
            this.updateIntercom()
          }, 10000)
          this.firstUpdatePending = true
          return
        }

        userData = {
          app_id: 'a772tk9n',
          email: user.email,
          user_id: user.id,
          name: user.displayName,
          created_at: user.createdAt,
          emailConfirmed: user.emailVerified, // this is with our custom confirmation flow
          "Premium Subscriber": user.isSubscriber,
          "Team Subscriber": this.$store.getters.teamPlan !== false,
          "Subscription Start": sub.start_date,
          "Subscription Period End": sub.current_period_end,
          "Subscription Status": sub.status,
          "Stripe Customer Id": sub.customer,
          "User Plan": this.$store.getters.userPlan,
          "Event Pass Start": passes.mostRecentPass,
          "Event Pass End": passes.passEnd,
          "Pass Holder": this.$store.getters.isPassHolder,
          "Slide Deck Count": _get(user, 'slideDecks_aggregate.aggregate.count'),
          "Event Count": user.event_count,
          "Onboard Started": user.onboardCues.includes('onboard-demo-started'),
          "Onboard Finished": user.onboardCues.includes('onboard-demo-finished')
        }

        if (this.userAudience && this.userAudience.response) {
          userData['User Audience'] = this.userAudience.response
        }

        if (this.eventFrequency && this.eventFrequency.response) {
          userData['Event Frequency'] = this.eventFrequency.response
        }

        if (this.groupSize && this.groupSize.response) {
          userData['Group Size'] = this.groupSize.response
        }

        if (user?.metadata?.userType) { userData['Account Type'] = user?.metadata?.userType }
        if (user?.metadata?.groupSize) { userData['Group Size Num'] = user?.metadata?.groupSize }
        if (user?.metadata?.orgName) { userData['Company Name'] = user?.metadata?.orgName }
        if (user?.metadata?.orgRole) { userData['Company Role'] = user?.metadata?.orgRole }

        const validationStatus = user?.metadata?.validation?.status
        if (validationStatus) {
          // this.log('User email validation status: ', validationStatus)
          userData['Email Validation Status'] = user?.metadata?.validation?.status

          const isValid = validStatusList.includes(validationStatus)

          if (!isValid) {
            userData.unsubscribed_from_emails = true
            // this.log('User email marked invalid, unsubscribing from future emails')
          }
        }

        const newUser = getUnixTime(new Date()) - userCreatedTime < 45

        // this.log('user created at: ', getUnixTime(parseISO(user.createdAt)))

        if (newUser && localStorage && localStorage.getItem('firstLanding')) {
          const landing = JSON.parse(localStorage.getItem('firstLanding'))
          this.log('newly created user, firstLanding referral data: ', landing)
          userData['Landing Page'] = landing.url || undefined
          userData['Landing Source'] = landing.source || undefined
          userData['PPC Keyword'] = landing.keyword || undefined
        }

        if (!this.profitwellBooted) {
          window.profitwell('user_email', userData.email)
          this.profitwellBooted = true
        }
      }

      // check that intercom has started, if not wait and try again
      if (!window.Intercom && this.triedUpdateIntercom < 5) {
        setTimeout(() => {
          this.updateIntercom()
          this.triedUpdateIntercom++
        }, 1000)
        return
      }

      // give up if we've already tried to boot 3 times
      if (!window.Intercom) { return }

      if (this.intercomBooted) {
        this.log('updating intercom user data: ', userData)
        window.Intercom('update', userData)
        this.intercomUpdated = true
      } else {
        this.log('booting intercom for the first time: ', userData)
        window.Intercom('boot', userData)
        this.intercomBooted = true
      }
    }
  }
}
</script>
