import { parseISO, formatISO, addDays, differenceInDays, getUnixTime, getTime } from 'date-fns'
import { saveUserOnboarding } from '~/queries/updateUserProfile.gql'
import { getCurrentTime } from '~/queries/utilities.gql'

export const state = () => ({
  siteUrl: process.env.SITE_URL,
  isDev: process.env.NODE_ENV === 'development' || process.env.SITE_URL === 'https://dev.slideswith.com',
  activeEvent: {
    id: '',
    shortcode: '',
    player: {},
    lastActive: null
  },
  hostJoinedAsPlayer: false,
  user: {
    team: {}
  },
  confirmationEmailSent: false,
  confirmEmailModalShown: false,
  eventPreviews: {},
  slideDecks: [],
  popularDecks: [],
  userHints: [],
  slideDecksLoaded: false,
  currentSlideDeck: {},
  closeOnboarding: 0,
  isFullscreen: false,
  currentSteps: [
    {
      name: 'defaultStep'
    }
  ],
  pastEvents: [],
  emailSubscriber: false,
  showTestControls: true,
  serverTimeAtLoad: false,
  clientTimeOffset: 0,
  lastClosedEvent: {},
  showImportModal: false,
  importedDecks: [],
  importedDecksLimit: 2,
  playerLimits: {
    public: 1,
    free: 10,
    starter: 50,
    pro: 250,
    pro100: 100,
    pro250: 250, // duplicate of pro with updated naming
    pro500: 500,
    pro1000: 1000,
    admin: 1000
  },
  playerOverflow: {
    public: 0,
    free: 2,
    starter: 5,
    pro: 20,
    pro100: 10,
    pro250: 20, // duplicate of pro with updated naming
    pro500: 50,
    pro1000: 100,
    admin: 500
  },
  testProducts: {
    starterPass: 'prod_KW8DXq3cyC72oR',
    starterSubscription: 'prod_JFNis58e9qlBOM',
    proPass100: 'prod_MyWQIvK1haITF5',
    proPass250: 'prod_KW8D0uzlZQ90nd',
    proPass500: 'prod_MLFAcBIv99te9G',
    proPass1000: 'prod_MLFA7zyW4cjQUP',
    proSub100: 'prod_MyWR17KcybxToR',
    proSub250: 'prod_IFYFDWCiBQ97Hh',
    proSub500: 'prod_MLExvDskFRM9eq',
    proSub1000: 'prod_MLEyVfsJT3aL5G'
  },
  liveProducts: {
    starterPass: 'prod_KW4Bgn8aXiK38y',
    starterSubscription: 'prod_JAY0L2icmMv4Yp',
    proPass100: 'prod_MyWLMW7XhWl7xz',
    proPass250: 'prod_KW4Buc2G02OHtr',
    proPass500: 'prod_MJKqXacICC1kcK',
    proPass1000: 'prod_MJKugBj2JYteOz',
    proSub100: 'prod_MyWMDWkMnQs3cK',
    proSub250: 'prod_IFZ7iYFY3K4OFu',
    proSub500: 'prod_MJKw1ZdM4J0yWd',
    proSub1000: 'prod_MJKxn6tWh5IkC3'
  }
})

export const getters = {
  isAdminUser (state) {
    if (!state.auth.user) { return false }
    const emails = ['mason.hipp@gmail.com', 'crrazak@gmail.com', 'test@slideswith.com']
    return emails.includes(state.user.email)
  },
  products (state) {
    return state.isDev ? state.testProducts : state.liveProducts
  },
  forceFreeUser () {
    return process.env.NODE_ENV === 'development' && process.env.TEST_FREE_USER === 'true'
  },
  isProUser (state, getters) {
    if (getters.forceFreeUser) { return false }
    return ['admin', 'pro100', 'pro250', 'pro500', 'pro1000'].includes(getters.userPlan)
  },
  subscriptionPlan (state, getters) {
    if (getters.forceFreeUser) { return 'free' }

    if (!state.auth.loggedIn || !state.auth.user || !state.user) { return 'free' }
    const subscription = state.user.subscription

    if (!subscription || subscription.status !== 'active') { return 'free' }

    const products = getters.products
    const plan = Object.keys(products).find(key => products[key] === subscription.product)
    if (plan) {
      // transform to player limit key
      return plan.replace('Subscription', '').replace('Sub', '').replace('Pass', '')
    } else {
      return 'free'
    }
  },
  teamPlan (state) {
    if (!state.auth.loggedIn || !state.auth.user || !state.user) { return false }
    const member = state.user?.teamMembers?.[0]
    if (!member || !member.product) { return false }

    const products = state.liveProducts // no test products for team plans
    const plan = Object.keys(products).find(key => products[key] === member.product)
    if (plan) {
      // transform to player limit key
      return plan.replace('Subscription', '').replace('Sub', '').replace('Pass', '')
    } else {
      return false
    }
  },
  userPlan (state, getters) {
    if (getters.forceFreeUser) { return 'free' }

    if (!state.auth.loggedIn || !state.auth.user || !state.user) { return 'free' }
    if (getters.isAdminUser) { return 'admin' }

    const playerLimits = state.playerLimits

    // if active subscription, set upgraded plan
    let userPlan = 'free'
    userPlan = getters.subscriptionPlan

    // check if there is a team plan that is better
    const teamPlan = getters.teamPlan || 'free'
    if (playerLimits[teamPlan] > playerLimits[userPlan]) {
      userPlan = teamPlan
    }

    // see if there is an active pass that has a better plan
    const bestPass = getters.isPassHolder ? getters.eventPasses.bestPass : 'free'
    if (playerLimits[bestPass] > playerLimits[userPlan]) {
      userPlan = bestPass
    }

    return userPlan
  },
  eventPasses (state, getters) {
    const passes = state.user.checkoutSessions || []
    const today = parseISO(state.serverTimeAtLoad)

    const activePasses = []
    let bestPass = 'free'
    let mostDaysLeft = -1
    const hasPass = passes.length > 0
    let mostRecentPass = null

    passes.forEach((pass) => {
      if (pass.paymentStatus !== 'paid') { return } // remove unpaid purchases

      const createdAt = parseISO(pass.createdAt)
      pass.daysLeft = 30 - differenceInDays(today, createdAt)
      if (pass.daysLeft < 0) { return } // remove expired purchases

      // update longest pass available
      if (pass.daysLeft > mostDaysLeft) {
        mostDaysLeft = pass.daysLeft
        mostRecentPass = pass.createdAt
      }

      if (pass.product === getters.products.starterPass) {
        pass.type = 'starter'
        if (bestPass === 'free') { bestPass = 'starter' }
      } else if (pass.product === getters.products.proPass100) {
        pass.type = 'pro100'
        if (['free', 'starter'].includes(bestPass)) { bestPass = 'pro100' }
      } else if (pass.product === getters.products.proPass250) {
        pass.type = 'pro250'
        if (['free', 'starter', 'pro100'].includes(bestPass)) { bestPass = 'pro250' }
      } else if (pass.product === getters.products.proPass500) {
        pass.type = 'pro500'
        if (['free', 'starter', 'pro100', 'pro250'].includes(bestPass)) { bestPass = 'pro500' }
      } else if (pass.product === getters.products.proPass1000) {
        pass.type = 'pro1000'
        if (['free', 'starter', 'pro100', 'pro250', 'pro500'].includes(bestPass)) { bestPass = 'pro1000' }
      } else {
        return
      }

      activePasses.push(pass)
    })

    let passEnd
    if (mostRecentPass) {
      passEnd = formatISO(addDays(parseISO(mostRecentPass), 30))
    }

    return {
      daysLeft: mostDaysLeft,
      bestPass,
      activePasses,
      mostRecentPass,
      hasPass,
      passEnd
    }
  },
  isSubscriber (state, getters) {
    if (!state.auth.loggedIn || !state.auth.user || !state.user) { return false }
    return state.user.isSubscriber || getters.teamPlan
  },
  isPassHolder (state, getters) {
    if (!state.auth.loggedIn || !state.auth.user || !state.user) { return false }
    return getters.eventPasses.bestPass !== 'free'
  },
  isPassActive (state, getters) {
    // is an active event pass is taking precedent over a subscription?

    if (!getters.isPassHolder) { return false } // no pass, return false
    if (!getters.isSubscriber) { return true } // has pass but not subscription, return true

    const subPlayerLimit = state.playerLimits[getters.subscriptionPlan]
    const passPlayerLimit = state.playerLimits[getters.eventPasses.bestPass]
    if (passPlayerLimit > subPlayerLimit) {
      return true // pass has higher player limit than subscription
    }

    return false // all other combos subscription takes precedent
  },
  playerLimit (state, getters) {
    const plan = getters.userPlan
    return state.playerLimits[plan]
  },
  playerOverflow (state, getters) {
    const plan = getters.userPlan
    return state.playerOverflow[plan]
  },
  warnings (state) {
    const warnings = []
    const deck = state.currentSlideDeck
    const slides = deck.slides

    // throw a warning for scorable multi choice or text answer slide if there are no correct answers
    const answerSlides = slides.filter((slide) => {
      const isScorableType = slide.template === 'multipleChoice' || slide.template === 'specificTextAnswer'
      const isSetToScore = slide.options.shouldScore && slide.options.answerScoreMultiplier !== 0
      return isScorableType && (isSetToScore || slide.options.isTiebreak)
    })

    answerSlides.forEach((slide, key) => {
      const defaultAnswers = slide.answers.filter((answer) => {
        return answer.text === 'First Answer (Correct)' || answer.text === 'First Correct Answer'
      })
      if (defaultAnswers.length > 0 && slide.answers.length === 1) {
        warnings.push({
          slideId: slide.id,
          order: slide.order,
          type: 'onlyDefaultAnswer',
          message: `Add or customize the answers for this slide here 🙌`
        })
      }

      const correctAnswers = slide.answers.filter(answer => answer.isCorrect)
      if (correctAnswers.length < 1) {
        warnings.push({
          slideId: slide.id,
          order: slide.order,
          type: 'missingCorrectAnswer',
          message: `This slide is set to score but doesn't have a correct answer 🤔`
        })
      }
    })

    // throw a warning if a slide has duplicate answers
    // const prevSlide = slides[key - 1] || null
    // const nextSlide = slides[key + 1] || null

    return warnings
  }
  // team (state) {
  //   const user = state.user
  //   const teamMembers = user?.teamMembers?.[0]
  //   const team = teamMembers?.team

  //   return {
  //     id: teamMembers?.teamId,
  //     name: team?.name,
  //     ownerId: team?.ownerId
  //   }
  // }
}

export const mutations = {
  clearLocalData (state) {
    state.confirmEmailModalShown = false
    state.confirmationEmailSent = false
  },
  confirmEmailModalShown (state) {
    state.confirmEmailModalShown = getUnixTime(Date.now())
  },
  confirmationEmailSent (state, val) {
    state.confirmationEmailSent = val
  },
  setFullscreen (state, val) {
    state.isFullscreen = val
  },
  setServerTimeAtLoad (state, time) {
    state.serverTimeAtLoad = time
    state.clientTimeOffset = parseISO(time) - Date.now()
    const now = Date.now()
    if (state.isDev) {
      console.log('setting server time at load to: ' + time,
        'client time now: ' + now, 'server time now: ' + getTime(parseISO(time)))
      console.log('client time offset: ' + state.clientTimeOffset)
      console.log('corrected local time should equal server time: ', (now + state.clientTimeOffset))
    }
  },
  toggleShowTestControls (state) {
    state.showTestControls = !state.showTestControls
  },
  saveUserHint (state, name) {
    const userHints = [ ...state.userHints ]
    userHints.push(name)
    state.userHints = userHints
  },
  setHostAsPlayer (state, isPlayer = true) {
    state.hostJoinedAsPlayer = isPlayer
  },
  setPopularDecks (state, decks) {
    state.popularDecks = decks
  },
  setUserProfile (state, profile) {
    if (profile) {
      profile.isSubscriber = profile.subscription ? profile.subscription.status === 'active' : false
      // set easier version of slide deck count
      if (profile.slideDecks_aggregate && profile.slideDecks_aggregate.aggregate) {
        profile.slideDeckCount = profile.slideDecks_aggregate.aggregate.count || 0
      }
      profile.isEdu = profile?.metadata?.userType === 'school'

      const teamMembers = profile.teamMembers?.[0]
      const team = teamMembers?.team
      const canEdit = teamMembers?.canEdit || false

      profile.team = {
        id: teamMembers?.teamId,
        name: team?.name,
        ownerId: team?.ownerId,
        canEdit
      }

      state.user = profile
    }
  },
  setSlideDeck (state, slideDeck) {
    state.currentSlideDeck = slideDeck
  },
  setActiveEvent (state, event) {
    state.pastEvents.push(state.activeEvent)
    event.lastActive = getUnixTime(Date.now())
    state.activeEvent = event
  },
  clearActiveEvent (state, event) {
    state.pastEvents.push(state.activeEvent)
    state.activeEvent = {
      id: '',
      shortcode: '',
      player: {},
      lastActive: null,
      playerFeedback: null
    }
  },
  setActivePlayer (state, player) {
    state.activeEvent.player = player
  },
  setImportedDecks (state, decks) {
    state.importedDecks = decks
  },
  setLastActive (state, timestamp) {
    // console.log('updating last active to: ' + timestamp)
    // console.log('previous interaction was ' + (timestamp - state.activeEvent.lastActive) + ' seconds ago')
    state.activeEvent.lastActive = timestamp
  },
  setSteps (state, steps) {
    state.currentSteps = steps
  },
  setSlideDecks (state, decks) {
    state.slideDecksLoaded = true
    state.slideDecks = decks
  },
  setEmailSubscriber (state, status) {
    state.emailSubscriber = status
  },
  saveEventPreviewCode (state, event) {
    state.eventPreviews[event.deckId] = {
      shortcode: event.shortcode,
      timestamp: event.timestamp
    }
  },
  closeOnboarding (state) {
    state.onboardingClosed = Date.now()
  },
  setLastClosedEvent (state, event) {
    state.lastClosedEvent = event
  },
  setShowImportModal (state, val = true) {
    state.showImportModal = val
  }
}

export const actions = {
  async saveOnboardCues (context, onboardCues) {
    const client = this.app.apolloProvider.defaultClient
    await client.mutate({
      mutation: saveUserOnboarding,
      variables: {
        userId: this.app.$auth.user.id,
        onboardCues
      },
      update: (cache, { data: newDeck }) => {
        // console.log('user onboarding cues saved')
      }
    })
  },
  async setServerTime (context) {
    const client = this.app.apolloProvider.defaultClient
    const { data } = await client.query({ query: getCurrentTime, fetchPolicy: 'no-cache' })
    const time = data?.getCurrentTime?.[0]?.timestamp

    if (time) {
      context.commit('setServerTimeAtLoad', time)
    }
  }
}
