/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import Vue from 'vue'
import { getNhostStorageUrl } from '../common/nhost-url'
export default ({ $axios, app }) => {
  Vue.prototype.$storage = {
    put: async (path, file, onUploadProgress) => {
      const storageUrl = `${getNhostStorageUrl()}/files`
      console.log('file', file)
      const data = new FormData()
      data.append('file', new File([file], path, { type: file.type }))
      const headers = {
        'Content-Type': 'multipart/form-data'
      }
      const accessToken = app.$auth.getAccessToken()
      if (accessToken) {
        headers.Authorization = accessToken
      }
      const { data: metadata } = await $axios.post(
        storageUrl,
        data,
        {
          onUploadProgress,
          headers
        }
      )
      return { metadata, downloadUrl: `${storageUrl}/${metadata.id}` }
    }
  }
}
