// eslint-disable-next-line import/no-named-as-default
import posthog from 'posthog-js'
import Vue from 'vue'

export default function ({ app: { router }, route }, inject) {
  if (process.env.DISABLE_POSTHOG === 'true' || window.Cypress) {
    console.log('Cypress detected or Posthog disabled from ENV, aborting initialization')
    return
  }

  // don't initialize posthog on embed pages
  if (route.path.includes('/embed/')) { return }

  console.log('initializing posthog')
  posthog.init('phc_5m4RcOafc1xcNivVKNvl5or7csq6qICQk9DW7rroQJN', {
    api_host: 'https://us.i.posthog.com',
    capture_pageview: false,
    autocapture: false,
    disable_session_recording: true
  })

  inject('posthog', posthog)

  router.afterEach((to) => {
    // eslint-disable-next-line import/no-named-as-default-member
    Vue.nextTick(() => {
      posthog.capture('$pageview', {
        $current_url: to.fullPath
      })
    })
  })
}
