import Vue from 'vue'

Vue.directive('editable', function (el, binding, vnode) {
  if (binding.arg === true) {
    el.contentEditable = true
    const hasFocus = document.activeElement === el

    if (!hasFocus) {
      el.textContent = binding.value
    }
  } else {
    if (el.contentEditable) { el.contentEditable = false }
    el.textContent = binding.value
  }
})
