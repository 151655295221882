<template>
  <div>
    <nuxt />
  </div>
</template>
<script>
import subscribeToUser from '~/queries/subscribeToUser.gql'
import { updateLastSeen } from '~/queries/updateUserProfile.gql'

export default {
  auth: false,
  watch: {
    // eslint-disable-next-line object-shorthand
    'auth.loggedIn': async function (newVal) {
      if (newVal === true) {
        await this.startProfileSubscription()
      } else {
        this.$apollo.subscriptions.userProfile.stop()
        this.$store.commit('setUserProfile', {})
      }
    }
  },
  mounted () {
    const isScreenshot = this.$route.query.screenshot

    if (!isScreenshot) {
      this.startProfileSubscription()
    }
  },
  methods: {
    async startProfileSubscription () {
      if (!this.$auth.loggedIn) { return }

      if (this.$apollo.subscriptions.userProfile) {
        await this.$apollo.subscriptions.userProfile.start()
        this.$nextTick(() => { this.updateLastSeen() })
      } else {
        await this.$apollo.addSmartSubscription('userProfile', {
          query: subscribeToUser,
          variables () {
            return {
              userId: this.$auth.user.id
            }
          },
          result ({ data }) {
            const user = data.user || {}
            this.$store.commit('setUserProfile', user)
          }
        })
        this.$nextTick(() => { this.updateLastSeen() })
      }
    },
    async updateLastSeen () {
      if (!this.$auth.user.id || !this.$auth.loggedIn) { return }
      this.log('updating user last seen')

      const { data } = await this.$apollo.mutate({
        mutation: updateLastSeen,
        variables: {
          userId: this.$auth.user.id
        }
      })

      const currentTime = data.updateUser.updatedAt
      this.$store.commit('setServerTimeAtLoad', currentTime)
    }
  }
}
</script>
<style lang="scss">
html, body {
  background-color: transparent;
}
</style>
